import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import PageHeader from '../components/PageHeader'
import PageWrapper from '../components/PageWrapper'
import { css } from '@emotion/core'
import Img from 'gatsby-image'
import ExploreFurther from '../components/ExploreFurther'
import './page.css'

export default ({ data }) => {
    const page = data.wordpressPage
    return (
        <Layout>
            <Helmet>
                <title>{page.title} / {data.site.siteMetadata.title}</title>
            </Helmet>
            <main>
                <article
                    css={css`
                    `}
                >
                    <PageHeader title={page.title} excerpt={page.excerpt} />
                    { data.imageHero &&
                        <figure
                            css={css`
                                position: relative;
                                z-index: 1;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    display: block;
                                    top: 50%;
                                    bottom: 0;
                                    width: 100vw;
                                    left: 50%;
                                    margin-left: -50vw;
                                    background-color: #fff;
                                }
                                @media( max-width: 48em ) {
                                    position: relative;
                                    margin: -2em -7em -1em;
                                }
                                @media ( min-width: 48em ) {
                                    position: relative;
                                    max-width: 52em;
                                    margin: -2em auto -1em;
                                }
                            `}
                        >
                            <Img fluid={data.imageHero.childImageSharp.fluid} alt="" />
                        </figure>
                    }
                    <PageWrapper>
                        <div className='copy' dangerouslySetInnerHTML={{ __html: page.content }} />
                        <ExploreFurther />
                    </PageWrapper>
                </article>
            </main>
        </Layout>
    )
}
export const query = graphql`
    query($id: String!, $slug: String!) {
        wordpressPage(id: { eq: $id }) {
            title
            content
            excerpt
        }
        imageHero: file(name: { eq: $slug }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
            }
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`
